import { default as MultiCarousel } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import left_arrow from "./images/carousel/left.png"
import right_arrow from "./images/carousel/right.png"

function RArrow({onClick, ...rest}) {
	return ( 
        <button className={"absolute -right-16"} onClick={() =>onClick()}>
			{<img className="size-auto" src={right_arrow} alt={"Right arrow"} />}
		</button>
     );
}

function LArrow({onClick, ...rest}) {
	return ( 
        <button className={"absolute -left-16"} onClick={() =>onClick()}>
			{<img className="size-auto" src={left_arrow} alt={"Left arrow"} />}
		</button>
     );
}

function Carousel(props) {
    const products = props.products;

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
			partialVisibilityGutter: 40
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 4,
			partialVisibilityGutter: 40
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 3,
			partialVisibilityGutter: 30
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 2,
			partialVisibilityGutter: 20
		}
	};

    return ( 
        <MultiCarousel 
            responsive={responsive}
            infinite={true}
            draggable={false}
            containerClass="carousel-container container mx-auto static w-full"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customRightArrow={<RArrow />}
            customLeftArrow={<LArrow />}
        >
            { 
                products.map((product, index) => (
                        <div className='m-4'>
                            <img className="w-full" src={require('./images/products/' + product.image + '.png')} alt={product.name} />
							<p className="p-2 font-light text-normal text-primary text-center">{product.name.toUpperCase()}</p>
                        </div> 
                    )
                )
            }
        </MultiCarousel>
     );
}

export default Carousel;