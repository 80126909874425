import Navbar from './Navbar';
import Home from './Home';
import Products from './Products'
import './styles.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import { useEffect, useState } from 'react';

function App() {
	const [products, setProducts] = useState(null);
	const [isPending, setIsPending] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const abortCont = new AbortController();

		setTimeout(() => {
			fetch('/api/products'/*'http://localhost:8000/products'*/, { signal: abortCont.signal })
				.then(res => {
					if (!res.ok) {
						throw Error('Nepodařilo se získat data!');
					}
					return res.json();
				})
				.then(json => {
					setProducts(json);
					setIsPending(false);
				})
				.catch(err => {
					if (err.name === 'AbortError') {
						console.log('Fetch aborted');
					}
					else {
						setIsPending(false);
						setError(err.message);
					}
				})
		}, 100);

		return () => abortCont.abort();
	}, []);

	let content = null;
	if (isPending) {
		content = <p className='text-center'>Načítání...</p>
	} 
	else if (error) {
		content = <p className='text-center'>Při načítání došlo k chybě :&#40;</p>
	} 
	else {
		content = 
			<div className="App flex flex-col items-center max-w-full overflow-x-hidden">
				<Navbar />
				<Switch>
					<Route exact path="/">
						<Home products={products} />
					</Route>
					<Route path="products">
						<Products />
					</Route>
				</Switch>
			</div>
	}

	return (
		<Router>
			{ content }
		</Router>
  );
}

export default App;
