import Carousel from "./Carousel";
import decoration from "./images/home/decoration.png"
import logo from "./images/home/logo.png"
import welcome_pic from "./images/home/welcome_pic.jpg"

function Home(props) {
	const products = props.products;

	return ( 
        <div className="Home w-9/12 flex flex-col items-center">
			
            <div className="flex flex-col items-center bg-secondary w-screen relative min-h-64">
				<img className='max-w-sm pt-8' src={logo} alt='Logo' />			
			</div>
			<div className="min-h-[24rem] flex items-center relative">
				<img className='max-w-[50rem] absolute -top-24 -translate-x-1/2' src={welcome_pic} alt="Welcome" />	
			</div>

			{/* carousel */}
            <div className="flex flex-row w-full mt-16 mb-16 justify-between">
				<p className='font-regular text-normal text-primary'>UKÁZKA PRODUKTŮ</p>
				<img className='max-w-16 max-h-4' src={decoration} alt="Decoration" />
			</div>
			<div className="container mx-auto relative">
				{ products && <Carousel products={products} />}
			</div>
        
			<button className='btn border-2 w-96 m-8'>PROZKOUMAT VÍCE</button>
        </div>
     );
}

export default Home;