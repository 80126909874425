import { Link } from "react-router-dom";
import logo from "./images/navbar/logo.png"

function Navbar() {
    return ( 
        <div className="flex flex-col items-stretch">
            <div className="bg-secondary w-screen p-2 m-0">
				<div className="flex flex-row justify-center items-center">
					<img className="max-w-[36rem] max-h-12 pr-8" src={logo} alt="Logo" />
					<Link className="btn-navbar" to="/">O MNĚ</Link>
					<Link className="btn-navbar" to="/producty">PRODUKTY</Link>
					<Link className="btn-navbar" to="/vse-o-nakupu">VŠE O NÁKUPU</Link>
					<Link className="btn-navbar" to="/doprava-a-platba">DOPRAVA A PLATBA</Link>
					<Link className="btn-navbar" to="/kontakty">KONTAKTY</Link>
				</div>
			</div>
            
        </div>
     );
}

export default Navbar;

